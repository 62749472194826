<template>
  <web-requests />
</template>

<script>
import webRequests from './webRequests/list.vue'
import incompletedEntries from './incompletedEntries/list.vue'

export default {
  components: { webRequests, incompletedEntries },
}
</script>

<style></style>
