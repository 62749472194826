<template>
  <div>
    <searchable-table
      :items="items"
      :fields="fields"
      hover
      @row-clicked="showDetails"
    >
      <!-- <template #phone="{ item }">
        <span>
          {{ item.phone | VMask("(###) ### ## ##") }}
        </span>
      </template> -->
    </searchable-table>
  </div>
</template>

<script>
import sidebarContent from './sidebarContent.vue'

export default {
  data() {
    return {
      items: [],
      sidebarContent,
      fields: [
        { key: 'phone', label: 'Telefon' },
        { key: 'createdAt', label: 'Oluşturulma Tarihi', formatDateTime: true },
      ],
    }
  },
  mounted() {
    this.$axios
      .get('/web/form/incomplete-entries')
      .then(response => {
        this.items = response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    showDetails(item) {
      this.$showAppSidebar('Tamamlanmayan Girişler', sidebarContent, item)
    },
  },
}
</script>

<style></style>
